var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.slug,attrs:{"id":"map-list"}},[_c('b-col',{staticClass:"map-heading",attrs:{"cols":"12"}},[_c('div',[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Puoi cliccare su un qualsiasi punto della mappa per cercare in quel luogo'),expression:"'Puoi cliccare su un qualsiasi punto della mappa per cercare in quel luogo'",modifiers:{"hover":true,"top":true}}]},[_c('SvgInfoCircle'),_vm._v(" "+_vm._s(_vm.geo.label)+" ")],1)]),_c('span',{on:{"click":function($event){return _vm.mixinGoTo('search',{})}}},[_c('BtnBack',{attrs:{"text":'Vai alla lista'}})],1)]),_c('b-col',{staticClass:"map-wrapper",attrs:{"cols":"12","xl":"7","xxl":"8","order-xl":"2"}},[_c('div',{ref:"map",staticClass:"map-container",attrs:{"id":"map"}})]),_c('b-col',{staticClass:"scroller-wrapper",attrs:{"cols":"12","xl":"5","xxl":"4","order-xl":"1"}},[_c('div',{staticClass:"map-virtual-scroller-wrap",on:{"wheel":_vm.scrollSlider}},[(!_vm.loading)?_c('DynamicScroller',{key:_vm.scrollerDirection,ref:"map_virtual_scroller",staticClass:"scroller",attrs:{"items":_vm.properties,"item-size":_vm.itemSize/*null*/,"min-item-size":100/*350*/,"key-field":"property_id","direction":_vm.scrollerDirection},on:{"visible":_vm.scrollerInit,"update":_vm.scrollerUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"data-index":index,"propertyId":item.property_id,"active":active,"size-dependencies":[/*
                                item.name,
                                item.address1,
                                item.city
                            */]}},[_c('PropertyPad',{attrs:{"property":item,"index":index,"showLite":false}})],1)]}}],null,false,1616476438)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }