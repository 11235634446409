<template>
    <div :class="slug" id="map-list">
        <!--<b-row>-->
            <b-col cols="12" class="map-heading">
                <div>
                    <span v-b-popover.hover.top="'Puoi cliccare su un qualsiasi punto della mappa per cercare in quel luogo'" >
                        <SvgInfoCircle /> {{geo.label}}
                    </span>
                </div>
                <span @click="mixinGoTo('search',{})">
                    <BtnBack :text="'Vai alla lista'" />
                </span>
            </b-col>

            <b-col cols="12" xl="7" xxl="8" order-xl="2" class="map-wrapper">
                <div id="map" ref="map" class="map-container"></div>
            </b-col>

            <b-col cols="12" xl="5" xxl="4" order-xl="1" class="scroller-wrapper">
                <div class="map-virtual-scroller-wrap" @wheel="scrollSlider">
                    <DynamicScroller
                        v-if="!loading"
                        :key="scrollerDirection"
                        ref='map_virtual_scroller'
                        :items="properties"
                        :item-size="itemSize/*null*/"
                        :min-item-size="100/*350*/"
                        key-field="property_id"
                        class="scroller"
                        :direction="scrollerDirection"
                        v-on:visible="scrollerInit"
                        v-on:update="scrollerUpdate"
                    >
                        <template v-slot="{ item, index, active }">
                            <DynamicScrollerItem
                                :item="item"
                                :data-index="index"
                                :propertyId="item.property_id"
                                :active="active"
                                :size-dependencies="[/*
                                    item.name,
                                    item.address1,
                                    item.city
                                */]"
                            >
                            <PropertyPad
                                :property="item"
                                :index="index"
                                :showLite="false"
                            />
                            </DynamicScrollerItem>
                        </template>
                    </DynamicScroller>
                </div>
            </b-col>
        <!--</b-row>-->
    </div>
</template>

<script>
    import Vue from "vue"
    import _ from 'lodash'
    //import { MarkerClusterer } from "@googlemaps/markerclusterer"
    import { mapGetters } from 'vuex'
    //import Spinner from '@/components/atoms/spinner'
    import Axios from "axios"
    import PropertyPad from '@/components/blocks/property/property-pad'
    import BtnBack from '@/components/atoms/btn-back'
    import SvgInfoCircle from '@/components/svg/info-circle'

    export default {
        name: 'google-map',
        components:{
            //Slick,
            PropertyPad,
            //Spinner,
            BtnBack,
            SvgInfoCircle,
            //SvgChevronLeft,
            //SvgChevronRight,
        },
        props: {
            slug: String,
            title: String,
            property: 
            {
                type: Object,
                default: () => new Object(),
            }
        },
        computed:{
            ...mapGetters({
                geo:    'getGeo',
                map:    'getMap',
            }),
            computedResultsFiltered: function() {
                return this.$parent.resultsFiltered
            },
        },
        data() {
            return {
                properties: [],
                scrollerDirection: 'horizontal',
                googleMap: {},
                googleMapCircle: {},
                googleMapPolygon: {},
                googleMapInfoWindow: {},
                markers: [],
                paths: [],
                loading: true,
                selectedGeo: {},
                showInfo: false,
                visibleProperties: [],
                mapStyle: {
                    /* non serve: verrà centrata con fitBounds una volta caricati il/i marker/s
                    center: {
                        lat: 12,
                        lng: 12
                    },
                    */
                    scrollwheel:true,
                    zoom: 8,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    styles: 
                    [
                        // snazzy maps json here
                    ],
                    options:{
                        gestureHandling: 'greedy', // zoom without ctrl+
                    },
                },
                here_apikey: Vue.prototype.$config.here.apikey,
                reverse_geocode_endpoint: Vue.prototype.$config.here.reverse_geocode_endpoint,
                customMarkerColor: (Vue.prototype.$config.guiSettings.customizations !== undefined) ? Vue.prototype.$config.guiSettings.customizations.colors.primary : '#0077CA'
            }
        },
        created() {
            window.addEventListener('resize', _.debounce(this.setScrollerDirection,250))
            document.addEventListener('googleMapEvent', this.handleMapEvents)            
        },
        destroyed() {
            window.removeEventListener('resize', this.setScrollerDirection)
            document.removeEventListener("googleMapEvent", this.handleMapEvents)
        },
        beforeMount()
        {
            // https://dev.to/tareqnewazshahriar/how-to-add-google-map-api-script-in-a-vue-project-without-plugins-3ldi
            window.checkAndAttachMapScript = function (callback)
            {
                let scriptId = "map-api-script"
                let mapAlreadyAttached = !!document.getElementById(scriptId)
                if (mapAlreadyAttached)
                {
                    if (window.google) callback()
                }
                else
                {
                    window.mapApiInitialized = callback
                    let script = document.createElement('script')
                    script.id = scriptId
                    script.src = 'https://maps.googleapis.com/maps/api/js?key='+Vue.prototype.$config.googleMapsApikey+'&libraries=places,geometry&callback=mapApiInitialized'
                    document.body.appendChild(script)
                }
                return mapAlreadyAttached
            }
        },
        mounted()
        {
            this.handleProperties()
            this.setScrollerDirection()
            window.checkAndAttachMapScript(this.initMap)
        },
        methods:{
            handleMapEvents(event)
            {
                switch(event.detail.mapEventType)
                {
                    case 'selectPoint':
                        this.selectPoint()
                        break;
                
                    default:
                        break;
                }
            },

            handleProperties()
            {
                if(Object.values(this.property).length > 0)
                {
                    this.properties = [this.property]
                }
                else
                {
                    this.properties = this.map.data
                }
            },

            selectPoint()
            {
                this.mixinSendMutation('setGeo',{
                    latitude:   this.selectedGeo.latitude,
                    longitude:  this.selectedGeo.longitude,
                    matchLevel: this.selectedGeo.matchLevel,
                    label:      this.selectedGeo.label
                })
                this.mixinSendMutation('setLabel',this.selectedGeo.label)
                this.$parent.resetResults()
                this.$parent.getResults()
                this.mixinGoTo('search',{})
            },

            clickedMap(click)
            {
                let _this = this
                _this.showInfo = true

                let latitude = click.latLng.lat(),
                    longitude = click.latLng.lng()

                var params = {
                    apikey: this.here_apikey,
                    prox: latitude + ',' + longitude + ',5000',
                    mode: 'retrieveAreas', // nearest city
                    //mode: 'retrieveAll',
                }

                // reverse geocoding del punto selezionato
                // se ho errore, faccio alla vecchia maniera con label "punto selezionato"
                Axios
                    .get(this.reverse_geocode_endpoint, {params})
                    .then((response) => {

                    let place = false

                    response.data.Response.View[0].Result.forEach( result => {

                        if(result.MatchLevel == 'city') place = result.Location.Address

                    }) // each results


                    if(!place) place = response.data.Response.View[0].Result[0].Location.Address

                    let label = 'Vicino ';
                    if (place.City){

                        label += place.City
                        if (place.State) label += ', ' + place.State

                    }else{

                        label += place.Label

                    }

                    if (place.Country && place.Country != 'ITA') label += ' (' + place.Country + ')'

                    //let mapZoomLevel = _this.googleMap.getZoom()

                    // cambio il raggio del punto selezionato in base allo zoom level
                    // (solo grafica, la ricerca segue le sue logiche)
                    //_this.googleMapCircle.setRadius(1000 * (20 - mapZoomLevel))


                    _this.googleMapCircle.setCenter({
                        lat: latitude,
                        lng: longitude
                    })

                    _this.googleMapInfoWindow.setPosition({
                        lat: latitude,
                        lng: longitude
                    })

                    _this.selectedGeo = {
                        latitude: latitude,
                        longitude: longitude,
                        matchLevel: 'point',
                        label: label
                    }



                    }, (error) => {
                    if (window.LogRocket) window.LogRocket.track('error_mapReverseGeocoder')
                    if(window.clarity) window.clarity("set", "error", "mapReverseGeocoder")
                    window.console.log('reverse geocode error')
                    window.console.log(error)

                    //let mapZoomLevel = _this.$refs.map_list.$mapObject.zoom
                    //let mapZoomLevel = _this.googleMap.getZoom()

                    // cambio il raggio del punto selezionato in base allo zoom level
                    // (solo grafica, la ricerca segue le sue logiche)
                    //_this.googleMapCircle.setRadius(1000 * (20 - mapZoomLevel))


                    _this.googleMapCircle.setCenter({
                        lat: latitude,
                        lng: longitude
                    })

                    _this.googleMapInfoWindow.setPosition({
                        lat: latitude,
                        lng: longitude
                    })

                    _this.selectedGeo = {
                        latitude: latitude,
                        longitude: longitude,
                        matchLevel: 'point',
                        label: "punto selezionato"
                    }

                })
            },

            setScrollerDirection()
            {
                if(document.body.clientWidth < 1200)
                {
                    this.scrollerDirection = 'horizontal'
                    this.itemSize = 360
                }
                else
                {
                    this.scrollerDirection = 'vertical'
                    this.itemSize = 570
                }
            },

            initMap()
            {
                this.renderMap()
                this.renderMarkers()
                this.loading = false
            },

            renderMap()
            {
                this.googleMap = new window.google.maps.Map(
                    this.$refs['map'],
                    this.mapStyle
                )

                if(Object.values(this.map.geography)[0]){
                    let coordinates = Object.values(this.map.geography)[0].polygon.coordinates,
                        googleMapPolygon,
                        googleMapCircle,
                        googleMapInfoWindow
                    
                    for(var i = 0; i < coordinates[0].length; i++){
                        this.paths.push({lat: parseFloat(coordinates[0][i][1]), lng: parseFloat(coordinates[0][i][0])});
                    }
                    
                    googleMapPolygon = new window.google.maps.Polygon({
                        paths: this.paths,
                        editable: false,
                        strokeOpacity: 0.5,
                        strokeWeight: 1,
                        fillColor: '#EC7A60',
                        fillOpacity: 0.05
                    })
                    googleMapPolygon.setMap(this.googleMap)
                    this.googleMapPolygon = googleMapPolygon

                    googleMapCircle = new window.google.maps.Circle({
                        strokeColor:'#1d23ec',
                        strokeOpacity:.5,
                        strokeWheight:1.5,
                        fillColor:'#1d23ec',
                        fillOpacity:.2,
                        center: {lat:this.geo.latitude, lng:this.geo.longitude},
                        radius: 5000,
                    })
                    //googleMapCircle.setMap(this.googleMap)
                    this.googleMapCircle = googleMapCircle

                    googleMapInfoWindow = new window.google.maps.InfoWindow({
                        position: {lat:this.geo.latitude, lng:this.geo.longitude},
                        content: '<div><button class="btn btn-secondary" onclick="document.dispatchEvent(new CustomEvent(\'googleMapEvent\', {detail: {mapEventType: \'selectPoint\' }}))">Cerca nei paraggi</button></div>',
                    })
                    googleMapInfoWindow.setMap(this.googleMap)
                    googleMapInfoWindow.close(this.googleMap)
                    this.googleMapInfoWindow = googleMapInfoWindow
                    this.googleMap.addListener('click', this.clickedMap)
                    window.google.maps.event.addListener(googleMapPolygon,'click', this.clickedMap)
                    window.google.maps.event.addListener(googleMapInfoWindow,'closeclick', () => {this.showInfo = false})
                }
            },

            renderMarkers()
            {                
                let existingMarkers = this.markers.length

                this.properties.forEach((property, index) => {
                    if(index<existingMarkers) return
                    let marker = new window.google.maps.Marker(this.propertyToMarker(property)),
                        _this = this
                    window.google.maps.event.addListener(marker, "click", function() {
                        _this.clickedMarker(index)
                    })
                    marker.dataIndex = index
                    this.markers[index] = marker
                    this.markers[index].setMap(this.googleMap)
                })
                //if(this.properties.length > 0){
                    let bounds = new window.google.maps.LatLngBounds()
                    for (let i = 0; i < this.properties.length; i++) {
                        if(this.properties[i] && this.properties[i].latitude && this.properties[i].longitude){
                            bounds.extend(
                                new window.google.maps.LatLng({lat: parseFloat(this.properties[i].latitude), lng: parseFloat(this.properties[i].longitude)})
                            )
                        }
                    }
                    this.googleMap.fitBounds(bounds)
                //}
            },

            propertyToMarker(property)
            {
                let markerColor = this.customMarkerColor
                
                if(property.live_price == 0)
                {
                    markerColor = '#28a745' // verde
                }
                else
                {
                    let markerClass = this.mixinGetIntegrationInfo(property.live_price).class
                    if(markerClass == 'default') {
                        markerColor = this.customMarkerColor
                    }
                    if(markerClass == 'red') {
                        markerColor = '#aaaaaa'
                    }
                    if(markerClass == 'gold') {
                        markerColor = '#F9B03A'
                    }
                }

                return {
                    zIndex: 1,
                    title: property.name,
                    position:
                    {
                        lat: parseFloat(property.latitude),
                        lng: parseFloat(property.longitude)
                    },
                    icon:
                    {
                        url: 'data:image/svg+xml;base64,'+window.btoa('\
                            <svg width="30" height="38" viewBox="0 0 30 38" xmlns="http://www.w3.org/2000/svg">\
                                <path fill="'+markerColor+'" stroke="white" stroke-width="1" d="M 14.997 0.524 C 11.169 0.524 7.496 2.045 4.787 4.753 C 2.079 7.462 0.558 11.134 0.558 14.963 C 0.558 22.657 13.502 36.296 14.053 36.874 C 14.174 36.998 14.315 37.098 14.474 37.166 C 14.632 37.237 14.802 37.274 14.975 37.277 C 15.148 37.28 15.32 37.249 15.481 37.185 C 15.641 37.122 15.788 37.028 15.913 36.908 C 15.924 36.897 15.935 36.886 15.947 36.874 C 16.497 36.296 29.442 22.657 29.442 14.963 C 29.442 13.067 29.068 11.189 28.342 9.437 C 27.615 7.685 26.552 6.094 25.21 4.752 C 23.869 3.411 22.278 2.348 20.526 1.622 C 18.774 0.897 16.895 0.523 14.997 0.524 Z M 14.997 21.528 C 13.7 21.528 12.431 21.143 11.352 20.42 C 10.273 19.699 9.432 18.674 8.934 17.475 C 8.438 16.275 8.308 14.955 8.562 13.682 C 8.815 12.411 9.441 11.24 10.358 10.322 C 11.276 9.405 12.447 8.779 13.719 8.527 C 14.991 8.273 16.311 8.403 17.511 8.9 C 18.71 9.397 19.735 10.239 20.456 11.318 C 21.179 12.397 21.563 13.666 21.563 14.963 C 21.563 15.825 21.394 16.679 21.063 17.476 C 20.734 18.273 20.249 18.996 19.641 19.606 C 19.031 20.215 18.307 20.699 17.51 21.028 C 16.713 21.359 15.859 21.528 14.997 21.528 Z"/>\
                            </svg>'),
                    }
                }
            },

            clickedMarker(index)
            {
                let activeMarkers,
                    _this = this
                
                activeMarkers = this.markers.filter(function(marker){
                    return marker.zIndex > 1
                })
                activeMarkers.forEach(function(marker){
                    _this.setActiveMarker(marker.dataIndex, false)
                })
                this.setActiveMarker(index, true)
                this.$refs.map_virtual_scroller.scrollToItem(index)
            },

            scrollerUpdate(startIndex, endIndex)
            {
                (startIndex, endIndex)
            },

            setActiveMarker(index, active)
            {
                let markerProperty = {
                    name: this.properties[index].name,
                    latitude: this.properties[index].latitude,
                    longitude: this.properties[index].longitude,
                    live_price: active ? 0 : this.properties[index].live_price
                }
                this.propertyToMarker(markerProperty)
                this.markers[index].setZIndex(active ? 2 : 1)
                this.markers[index].setIcon(this.propertyToMarker(markerProperty).icon)
            },

            scrollerInit()
            {
                window.console.log('[search-google-map-list] - scrollerInit()')
                this.setActiveMarker(0, true)
            },
            
            scrollSlider(event) {
                (event)

                if(this.properties.length == 1) return

                let nodes = document.querySelectorAll('.vue-recycle-scroller__item-view > div'),
                    scroller = document.querySelectorAll('.vue-recycle-scroller')[0],
                    rect = scroller.getBoundingClientRect(),
                    scrollerOffsetTop = rect.top + window.scrollY,
                    scrollerOffsetLeft = rect.left + window.scrollX,
                    visibleProperties = []
                    
                if(this.scrollerDirection == 'vertical')
                {
                    nodes.forEach((node, index) => {
                        visibleProperties.push(nodes[index].getAttribute('propertyId'))
                        let nodeRect = node.getBoundingClientRect(),
                            offset = nodeRect.top + window.scrollY - scrollerOffsetTop,
                            //isActive = offset <= 570/2 && offset >= -570/2,
                            // la somma delle due soglie deve essere l'altezza della pad, cioè 550 + 20 di margine
                            isActive = offset <= 300 && offset >= -270,
                            dataIndex = parseInt(nodes[index].getAttribute('data-index'))
                        
                        this.setActiveMarker(dataIndex, isActive)
                    })
                }

                if(this.scrollerDirection == 'horizontal')
                {
                    nodes.forEach((node, index) => {
                        visibleProperties.push(nodes[index].getAttribute('propertyId'))
                        let nodeRect = node.getBoundingClientRect(),
                            offset = nodeRect.left + window.scrollX - scrollerOffsetLeft,
                            isActive = offset <= 360 && offset >= -50,
                            dataIndex = parseInt(nodes[index].getAttribute('data-index'))
                        
                        this.setActiveMarker(dataIndex, isActive)
                    })
                }

                this.visibleProperties = visibleProperties
                this.debouncedClearMarkers()
            },
            
            debouncedClearMarkers: _.debounce(function() {
                this.properties.forEach((p, index) => {
                    if (!this.visibleProperties.includes(p.property_id))
                    {
                        this.setActiveMarker(index, false)
                    }
                })
            }, 250)

        },
        watch: {
            showInfo: function()
            {
                this.$nextTick(function(){
                    if (this.showInfo)
                    {
                        this.googleMapInfoWindow.open(this.googleMap)
                        this.googleMapCircle.setMap(this.googleMap)
                    }
                    else
                    {
                        this.googleMapInfoWindow.close(this.googleMap)
                        this.googleMapCircle.setMap(null)
                    }
                })
            },
            sortResults: function () {
                window.console.log('[seatch-google-map-list] - sortResults()')
                this.renderMarkers()
            },
            computedResultsFiltered: function(){
                window.console.log('[seatch-google-map-list] - computedResultsFiltered()')
                this.handleProperties()
                this.renderMarkers()
            },
        },
    }
</script>

<style lang="scss">
    #map-list
    {
        display:flex;
        flex-wrap: wrap;
        .scroller
        {
            &::-webkit-scrollbar
            {
               display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .map-heading
        {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //padding:0 0 1rem 0;
            margin-bottom: 2rem;
            padding-right:0;
            font-size:$font-size-base;
            color:$black;
            font-weight: 400;
            flex-flow: column;
            padding-left: 0;

            @include media-breakpoint-up(lg) {
                flex-flow: row;
            }

            & > div {
                
                svg {
                    margin-right: 5px;
                }
            }

            & > span {
                margin-top: 10px;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }
            }

            .btn-back
            {
                font-weight:600;
            }
            @include media-breakpoint-up(lg)
            {
                //padding:1rem 0 2rem 0;
                font-size: 19px;
            }
        }

        .map-container
        {
            height: 100%;
            min-height:400px;
        }

        .map-virtual-scroller-wrap
        {
            height:400px;
            .property-pad
            {
                //max-height: 400px;
            }
            .scroller
            {
                height: 100%;
                //overflow: hidden;
            }
            @include media-breakpoint-up(lg)
            {
                height: 600px;
                .property-pad
                {
                    //max-height: 600px;
                }
            }
        }

        .property-pad
        {
            justify-content:flex-start;
            
            @include media-breakpoint-up(lg)
            {
                justify-content: center;
                margin-bottom:2rem;
            }

            &__inner
            {
                border-radius: $border-radius-lg;
                overflow: hidden;
                margin-bottom:0;
                box-shadow: none;
            }

            &__name
            {
                min-height: 0px;
                @include media-breakpoint-down(md)
                {
                    font-size: 16px;
                }
            }

            &__link
            {
                @include media-breakpoint-down(md)
                {
                    display:none;
                }
            }

            &__info
            {
                height:inherit;
                flex-direction:column;
                display: flex; 
            }

            &__thumb
            {
                width:100%;
                height:auto;
                min-height: auto;
                .property-pad__thumb-bg
                {
                    border-bottom-right-radius: 0px;
                    padding-right:0;
                    width:100%;
                    height: 200px;
                    min-height: auto;
                }
                &:before
                {
                    display:none;
                }
            }

            &__scorestars
            {
                display:none;
            }

            .property__resume
            {
                //width:100%;
                //flex-direction: row;
                .property-prices-total
                {
                    font-size: 18px;
                }
            }
        }

        .boxed
        {
            width:100%;
            flex-direction: column;
            padding:$grid-gutter-width/4;
            display: flex; 
            .desc,
            .side
            {
                width:100%;
                &:before{display:none;}
                margin:0;
                padding:0;
            }
        }
        #property-prices
        {
            display:flex;
            flex-wrap: wrap;
            flex-direction: row;
            hr
            {
                display:none;
            }
            button
            {
                width:100%;
                margin-top:$grid-gutter-width/2;
            }
        }
        .property__resume,
        .property-prices-occupancy
        {
            width:50%;
        }
        .property-prices-per-night
        {
            margin-bottom:0;
        }

        @include media-breakpoint-up(lg)
        {
            .map-wrapper
            {
                padding-right:0;
            }
            @include media-breakpoint-down(xl)
            {
                .scroller-wrapper,
                .map-wrapper
                {
                    padding-right:0;
                }
                .property-pad
                {
                    &__name
                    {
                        margin-bottom:1rem;
                    }
                    &__city,
                    &__rates
                    {
                        font-size: 13px;
                        *
                        {
                            font-size:13px;
                        }
                        display:flex;
                        align-items: center;
                        padding-left:0;
                        margin-left:32px;
                        line-height: 120%;
                        margin-bottom:4px;
                        svg
                        {
                            margin:0 6px 0 0;
                            width:14px;
                        }
                    }

                    .property-prices
                    {
                        margin-top:1rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg)
        {
            .map-virtual-scroller-wrap
            {
                height:600px;
            }
            .scroller-wrapper
            {
                padding-left:0;
                padding-right:0;
            }
            .map-wrapper
            {
                padding-left:0;
                padding-right:0;
                margin-bottom:2rem;
            }
            .property-pad
            {
                margin-right:2rem;
                width:300px;
            }
        }


        .gm-style [role=dialog]
        {
            /*
            background-color:$secondary;
            box-shadow: none;
            padding:0;
            *, button
            {
                background-color:transparent !important;
            }
            .gm-style-iw-d
            {
                width:100%;
                height:100%;
                display: block;
                border:10x solid red;
            }
            .btn
            {
                margin:0;
                padding:0;
                background:transparent;
                border:0;
            }
            */
        }
        
        .property-pad
        {
            height:550px;
            &__name
            {
                //min-height: 82px;   
                .bordered
                {
                    //padding-top:0;
                    &:before
                    {
                        height:100%;
                    }
                }
            }
            &__city,
            &__proximity,
            &__rates
            {
                display:flex;
            }
            /*
            &__thumb
            {
                flex:1;
            }
            &__thumb-bg
            {
                height:100% !important;
            }
            */
            &__info
            {
                .boxed
                {
                    flex:1;
                    justify-content:space-between;
                }
            }
            .property__resume
            {
                width:100%;
                *:not(.property-prices-total)
                {
                    display:none;
                }
                .property-prices-total
                {
                    font-size: 26px;
                    text-align:center;
                    padding-right:1.5rem;
                }
            }
        }
        .property-prices-occupancy
        {
            display:none;
        }
    }
</style>